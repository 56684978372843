* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

.App {
   text-align: left;
   display: flex;
   min-height: 100vh;
   align-items: top;
   color: white;
   padding: 10px;
   justify-content: center;
   background-image: linear-gradient(45deg, #000000, 20%, #d55725, 40%, #1766a8);
 }
 
 .auth-form-container, .login-form, .register-form {
   display: flex;
   flex-direction: column;
   text-align: right;
 }
 
 .form-heading {
   text-align: center;
   font-size: 1.5rem;
 }
 
 label {
   text-align: left;
   padding: 0.25rem 0;
 }
 
 .subtitle {
  text-align: center;
 }

 input {
   margin: 0.5rem 0;
   padding: 1rem;
   border: none;
   border-radius: 10px;
 }
 
 button {
   border: none;
   background-color: white;
   padding: 20px;
   border-radius: 10px;
   cursor: pointer;  
   color: #d55725;
 }
 
 .link-btn {
   background: none;
   color: white;
   text-decoration: underline;
 }

 .clockin-button {
  margin: 0.5rem;
  border: white;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;  
  color: black;
  min-width: 10em;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
     monospace;
 }
 
 .header {
   margin: 2em 0 2em 2em;
   padding-bottom: 1em;
   border-bottom: 1px solid;
 }
 
 .header a {
   margin: 0 1em 0 1em;
   font-size: 1.2em;
   text-decoration: none;
 }
 
 .content {
   margin-left: 2em;
   font-size: 1.5em;
 }
 
 .errorMessage {
  color: red;
  font-size: 0.75em;
  background-color: white;
  display: flex;
  padding: 1.5em;
  border: 1px solid white;
  border-radius: 10px;
  text-align: center;
  justify-content: center;
} 

.timeClockMessage {
  color: green;
  font-size: 0.75em;
  background-color: white;
  display: flex;
  padding: 1.5em;
  border: 1px solid white;
  border-radius: 10px;
  text-align: center;
  justify-content: center;
} 

.container {
  margin: 1rem;
  text-align: center;
}

.site-title {
  font-size: 1.5rem;
}

.nav {
  background-color: #333;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;
  padding: 0 1rem;
}

.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
}

.nav li.active {
  background-color: #555;
}

.nav li:hover {
  background-color: #777;
}

.app-button {
  background-color: white;
  color: black;
  /* display: flex; */
  /* justify-content: space-between; */
  align-items: stretch;
  gap: 2rem;
  padding: 1rem;
  min-width: 1em;
  border: 1px solid white;
  border-radius: 10px;

}

.app-button ul {
  /* padding: 0; */
  /* margin: 0; */
  list-style: none;
  /* display: flex; */
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 1rem;
  text-decoration: none;
}

.app-button li.active {
  background-color: #555;
}

.app-button li:hover {
  background-color: #777;
}

.app-button a {
  color: inherit;
  text-decoration: none;
  /* height: 100%; */
  display: flex;
  align-items: center;
  padding: 0rem;
  border: 1px solid white;
  border-radius: 10px;
  text-align: center;
  /* justify-content: center; */
  text-emphasis: bold;
}

@media screen and (max-width: 600px) {
  .home-container {
    text-align: left;
    font-size: 0.75rem;
    padding: 0.5rem;
    margin: 0.25rem;
  }
    
  .auth-form-container, .form-heading {
    padding: 0.25rem;
    /* border: 1px solid white; */
    /* border-radius: 10px; */
    margin: 0.25rem;
  font-size: 0.75rem;
  text-align: right;
  }

  .form-heading {
    text-align: center;
    font-size: 1rem;
  }

  .site-title {
    /* font-size: 0.5rem; */
    font-size: 1rem;
  }
}

